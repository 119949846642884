'use client'
import './page.module.styles.css'
import '../../../../locale/i18n'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useCallback } from 'react'
import { Buttons, ButtonCart } from '../button'
import { DownloadOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Input, Dropdown, Space, Spin, Empty } from 'antd'
import Link from 'next/link'
import Image from 'next/image'
import axios from 'axios'
//
import useUser from '../../../lib/useUser'
import useFcm from '../../../lib/useFcm'
import useNotification from 'src/app/lib/useNotification'
import { getMessaging, onMessage } from 'firebase/messaging'
import firebaseApp from '../../../lib/firebase'
import dayjs from 'dayjs'
import { HandleError } from 'src/app/components/notification'
//
import { useAppSelector, useAppDispatch } from 'src/redux/hook'
import { fetchCartData } from 'src/redux/features/getCart'
import { usePathname, useRouter } from 'next/navigation'
import * as Icon from 'react-feather'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import THFlag from '../../../image/icon/thailand-flag.svg'
import ENGFlag from '../../../image/icon/eng-flag.svg'
import LogoDeskTop from '../../../image/icon/logo-desktop.svg'
import LogoMobile from '../../../image/icon/logo-mobile.svg'
import UserLogin from '../../../image/icon/user-login.svg'

import { filter, size } from 'lodash'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const NavBar = () => {
  const { user } = useUser({
    redirectTo: false,
    redirectIfFound: false,
  })
  const isLoggedIn = user ? user.isLoggedIn : false
  const { fcmToken, fcmPermission } = useFcm()

  const pathname = usePathname()
  const dispatch = useAppDispatch()

  const getCart = useCallback(() => {
    dispatch(fetchCartData())
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
  }, [dispatch])

  const { notification, mutateNotification, isLoading } = useNotification()

  let unreadNotification = filter(notification, { is_read: false }) || null

  const isEmptyObject = (obj) =>
    Object.keys(obj).length === 0 && obj.constructor === Object

  useEffect(() => {
    getCart()
  }, [getCart])

  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      // console.log('Register from onMessage event')
      // console.log('Service worker available', fcmToken, fcmPermission)
      const messaging = getMessaging(firebaseApp)
      const unsubscribe = onMessage(messaging, () => {
        // console.log('Foreground push notification received:', payload)
        // Handle the received push notification while the app is in the foreground
        // You can display a notification or update the UI based on the payload
      })

      return () => {
        console.log('Unsubscribing from onMessage event')
        unsubscribe() // Unsubscribe from the onMessage event
      }
    }
  }, [fcmToken, fcmPermission])

  useEffect(() => {
    if (fcmToken !== '' && user && user?.notification_web_token !== fcmToken) {
      const payload = {
        token: fcmToken,
      }
      // call api to update fcm token ${fcmToken}
      axios
        .post('/api/fcm-tokens', payload)
        .then((response) => {
          console.log('FCM token updated successfully:', response.data)
        })
        .catch((error) => {
          console.error('Failed to update FCM token:', error)
          // Optionally, handle different types of errors or retry logic
        })
    }
  }, [user, fcmToken])

  return (
    <div className="fixed w-full z-20 height-nav">
      <div className="nav-lang">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <span className="text-nav-lang">
              ONE MALL สั่งสินค้าจากจีน
              <span className="hidden sm:inline-block pl-2">
                และ นำเข้าสินค้าจากจีน
              </span>
            </span>
            <div className="flex items-center justify-center gap-3">
              {isLoggedIn &&
                Array.isArray(notification) &&
                !isEmptyObject(notification) && (
                  <div className="hidden sm:flex">
                    <DropdownNoti
                      user={user}
                      unreadNotification={unreadNotification}
                      notification={notification}
                      mutateNotification={mutateNotification}
                      isLoading={isLoading}
                    />
                  </div>
                )}
              <DropdownLang />
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="nav-container bg-gradient-primary pt-4 sm:pt-2">
        <div className="container mx-auto">
          <SearchBar user={user} />
        </div>
        <div className="mx-auto container">
          <div className="relative flex flex-col">
            <LinkMenu pathname={pathname} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default NavBar

export const LinkMenu = (pathname) => {
  const newPathname = pathname ? pathname.pathname : null
  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'เกี่ยวกับ ONE MALL', href: '/contents/about-us' },
    { name: 'วิธีการสั่งซื้อ', href: '/contents/how-to-buy' },
    { name: 'ค่าบริการ', href: '/contents/article-shipping-cost' },
    { name: 'ข่าวสาร & โปรโมชั่น', href: '/new-promotion' },
    { name: 'ติดต่อเรา ONE MALL', href: '/contents/contactus' },
  ]

  // const items = [
  //   {
  //     key: '1',
  //     label: <Link href={'#'}>1st menu item</Link>,
  //   },
  // ]

  return (
    <div className="flex overflow-x-scroll overflow-y-hidden whitespace-nowrap space-x-2 sm:space-x-4 w-full justify-start sm:justify-center">
      {/* <Dropdown
        menu={{
          items,
        }}
        trigger={['click']}
        className="custom-dropdown-link"
      >
        <div onClick={(e) => e.preventDefault()} className="cursor-pointer">
          <Space className="gap-lang">
            <span className="item-menu-nav text-white">บริการของเรา</span>
            <CaretDownOutlined className="h-5 w-3 text-white" />
          </Space>
        </div>
      </Dropdown> */}

      {navigation.map((item) => (
        <Link
          key={item.name}
          href={item.href}
          className={classNames(
            newPathname === `/${item.href}`
              ? ' text-yellow-300'
              : 'item-menu-nav-hover',
            'item-menu-nav ',
          )}
        >
          {item.name}
        </Link>
      ))}
    </div>
  )
}

export const DropdownNoti = ({
  user,
  notification,
  unreadNotification,
  mutateNotification,
  isLoading,
}) => {
  const router = useRouter()

  const isLoggedIn = user ? user.isLoggedIn : false

  let unreadCount = size(filter(notification, { is_read: false })) || 0

  useEffect(() => {
    if (isLoggedIn) {
      mutateNotification()
    }
  }, [isLoggedIn, mutateNotification])

  const itemOnClickHandler = (values) => {
    if (!values || typeof values !== 'object') {
      console.error('Invalid or no values provided')
      return
    }

    const payload = {
      id: values.id,
    }

    const action_uri = values.action_uri

    axios
      .put('/api/fcm-tokens', payload)
      .then((resp) => {
        // Check for successful response in a range of successful codes
        if (resp.status === 200) {
          if (action_uri) {
            mutateNotification()
            router.push(action_uri)
          } else {
            console.error('No action URI defined, cannot navigate')
          }
        } else {
          console.error(`Unexpected response status: ${resp.status}`)
        }
      })
      .catch((error) => {
        HandleError('Update notification error:', error)
      })
  }

  const isEmptyObject = (obj) =>
    Object.keys(obj).length === 0 && obj.constructor === Object

  // Conditionally build items if notification is valid and not an empty object
  const items =
    Array.isArray(unreadNotification) && !isEmptyObject(unreadNotification)
      ? unreadNotification.map((n) => ({
          key: n.id,
          label: (
            <div
              className={`list-item-notification ${
                n?.is_read === false && 'active'
              }`}
            >
              <div className="item-start cursor-pointer">
                <div className="box-icon-noti">
                  <Icon.Package size={20} color="#ffffff" />
                  {n?.is_read === false && <div className="dot-active" />}
                </div>
                <div className="box-detail">
                  <span className="text-header-noti">{n?.title}</span>
                  <span className="text-message-noti">{n?.message}</span>
                  <div className="flex gap-2 items-center">
                    <span className="text-date-noti">
                      {dayjs(n?.created_at).format('YYYY-MM-DD')}
                    </span>
                    <span className="text-date-noti">|</span>
                    <span className="text-date-noti">
                      {dayjs(n?.created_at).format('HH:mm:ss')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ),
          onClick: () =>
            itemOnClickHandler({ id: n?.id, action_uri: n?.action_uri }),
        }))
      : []

  const empty = (
    <div className="flex items-center justify-center box-empty-noti">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  )

  return (
    <>
      <Dropdown
        menu={{
          items: items.length > 0 ? items : [{ key: 'empty', label: empty }],
          selectable: true,
        }}
        placement="bottomRight"
        arrow
        overlayClassName="dropdown-menu-content"
      >
        <Spin spinning={isLoading}>
          {Array.isArray(notification) && !isEmptyObject(notification) ? (
            <div className="cursor-pointer">
              <Space className="gap-2">
                <div className="flex relative">
                  {unreadCount > 0 && (
                    <div className="box-total">{unreadCount}</div>
                  )}
                  <Icon.Bell color="#ffffff" size={20} />
                </div>
                <span className="text-btn-lang">การแจ้งเตือน</span>
              </Space>
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Spin>
      </Dropdown>
    </>
  )
}

export const DropdownLang = () => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState('th')
  const itemOnClickHandler = (e) => {
    i18n.changeLanguage(e.key || 'th')
    setLang(e.key)
  }

  const items = [
    {
      key: 'en',
      label: (
        <div className="flex flex-row items-center text-20">
          <Image
            src={ENGFlag}
            alt="ENG"
            width={16}
            height={16}
            className="mr-2"
          />
          <span>English</span>
        </div>
      ),
      onClick: itemOnClickHandler,
    },
    {
      key: 'th',
      label: (
        <div className="flex flex-row items-center text-20">
          <Image
            src={THFlag}
            alt="TH"
            width={16}
            height={16}
            className="mr-2"
          />
          <span>ภาษาไทย</span>
        </div>
      ),
      onClick: itemOnClickHandler,
    },
  ]

  return (
    <>
      <Dropdown
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: ['th'],
        }}
      >
        <div className="cursor-pointer">
          <Space className="gap-lang">
            <Image
              src={lang === 'th' ? THFlag : ENGFlag}
              alt="TH"
              width={16}
              height={16}
              className="mr-2"
            />
            <span className="text-btn-lang">
              {lang === 'th' ? 'ภาษาไทย' : 'English'}
            </span>
            <ChevronDownIcon
              className="h-5 w-5 text-white"
              aria-hidden="true"
            />
          </Space>
        </div>
      </Dropdown>
    </>
  )
}

export const SearchBar = ({ user }) => {
  const cart = useAppSelector((state) => state.cart)
  // const [isEmpty, setTsEmpty] = useState(false)
  const router = useRouter()
  const [form] = Form.useForm()

  const isLoggedIn = user ? user.isLoggedIn : false
  const isPhoneNumberVerified = user ? user.phone_number_verified : false

  const { data, code, total } = cart.cart || {}

  useEffect(() => {
    // const isEmpty = data ? Object.keys(data).length === 0 : true
    // setTsEmpty(isEmpty)
  }, [data])

  useEffect(() => {
    if (code === 404 || code === 400) {
      // setTsEmpty(true)
    }
  }, [code])

  const getProductId = (searchWord) => {
    let id = null
    let type = null

    if (searchWord.includes('qr.1688.com')) {
      if (searchWord.includes('/offer?id=')) {
        const start = searchWord.indexOf('offer?id=') + 'offer?id='.length
        const end = searchWord.indexOf('.html?share_token=')
        id = searchWord.substring(start, end)
      } else if (searchWord.includes('offerId=')) {
        const start =
          searchWord.indexOf('m.1688.com%2Foffer%2F') +
          'm.1688.com%2Foffer%2F'.length
        const end = searchWord.indexOf('.html%3Fshare_token%')
        id = searchWord.substring(start, end)
      } else if (searchWord.includes('businessId%3D')) {
        const start =
          searchWord.indexOf('businessId%3D') + 'businessId%3D'.length
        const end = searchWord.indexOf('%26__share_id')
        id = searchWord.substring(start, end)
      } else if (searchWord.includes('Foffer%2F')) {
        const start = searchWord.indexOf('%2Foffer%2F') + '%2Foffer%2F'.length
        const end = searchWord.indexOf('.html')
        id = searchWord.substring(start, end)
      }
      type = '1688'
    } else if (searchWord.includes('m.tb.cn')) {
      if (searchWord.includes('Copy.1&id=')) {
        const start = searchWord.indexOf('Copy.1&id=') + 'Copy.1&id='.length
        const end = searchWord.indexOf('&detailSharePosition')
        id = searchWord.substring(start, end)
      } else if (searchWord.includes('.Copy.1&id=')) {
        const start = searchWord.indexOf('.Copy.1&id=') + '.Copy.1&id='.length
        const end = searchWord.indexOf('&sourceType=')
        id = searchWord.substring(start, end)
      } else if (searchWord.includes('/detail.html?id=')) {
        const start =
          searchWord.indexOf('/detail.html?id=') + '/detail.html?id='.length
        const end = searchWord.indexOf('&ut_sk=')
        id = searchWord.substring(start, end)
      }
      type = 'taobao'
    } else if (
      searchWord.includes('1688.com') ||
      searchWord.includes('show.1688.com') ||
      searchWord.includes('detail.1688.com')
    ) {
      const match = searchWord.match(/offer\/(.*?)\.htm/)
      if (match) id = match[1]
      type = '1688'
    } else if (
      searchWord.includes('item.taobao.com') ||
      searchWord.includes('m.intl.taobao.com') ||
      searchWord.includes('market.m.taobao.com')
    ) {
      const match = searchWord.match(/[&?]id=([\d]+)/)
      if (match) id = match[1]
      type = 'taobao'
    } else if (searchWord.includes('a.m.taobao.com')) {
      const match = searchWord.match(/i([\d]+)/)
      if (match) id = match[1]
      type = 'taobao'
    } else if (searchWord.includes('world.taobao.com')) {
      const match = searchWord.match(/item\/(.*?)\.htm/)
      if (match) id = match[1]
      type = 'taobao'
    } else if (searchWord.includes('detail.tmall')) {
      const match = searchWord.match(/[&?]id=([\d]+)/)
      if (match) id = match[1]
      type = 'taobao'
    } else if (searchWord.includes('world.tmall')) {
      const match = searchWord.match(/item\/(.*?)\.htm/)
      if (match) id = match[1]
      type = 'taobao'
    } else if (searchWord.includes('jd.com')) {
      const match = searchWord.match(/\d+/)
      if (match) id = match[0]
      type = 'jd'
    } else if (searchWord.includes('aliexpress.com')) {
      const match = searchWord.match(/\/([0-9]+)(?=[^\\/]*$)\.html/)
      if (match) id = match[1]
      type = 'aliexpress'
    } else if (searchWord.includes('alibaba.com')) {
      const split = searchWord.split('/')
      id = split[split.length - 1]
      type = 'alibaba'
    }
    const data = { provider: type, productId: id }
    console.log(data)
    return data
  }

  const handleSearch = async () => {
    const values = form.getFieldsValue()
    const { search_product } = values || {}

    if (search_product) {
      const cloneSearchProduct = values.search_product
      if (
        cloneSearchProduct.startsWith('http:') ||
        cloneSearchProduct.startsWith('https://')
      ) {
        const data = getProductId(cloneSearchProduct)
        if (data.productId) {
          form.resetFields()
          return router.push(
            `/product/${`ค้นหาด้วย URL`}/${data.productId}/${data.provider}`,
          )
        } else {
          form.resetFields()
          return router.push(
            `/product?search=${btoa(cloneSearchProduct)}&type=url`,
            undefined,
            {
              shallow: false,
            },
          )
        }
      } else {
        form.resetFields()
        return router.push(
          `/product?search=${cloneSearchProduct}&type=text`,
          undefined,
          {
            shallow: true,
          },
        )
      }
    }
  }

  const items = [
    {
      label: (
        <Link href="/login" className="link-dropdown-mb">
          <span className="text-link">
            <DownloadOutlined className="icon-login" />
            <span className="pl-2">เข้าสู่ระบบ</span>
          </span>
        </Link>
      ),
      key: '1',
    },
  ]
  const login = [
    {
      label: (
        <Link href="/profile/about-me" className="link-dropdown-mb">
          <span className="text-link">โปรไฟล์</span>
        </Link>
      ),
      key: '0',
    },
    {
      label: (
        <Link href="/profile/order" className="link-dropdown-mb">
          <span className="text-link">ประวัติการสั่งซื้อ</span>
        </Link>
      ),
      key: '1',
    },
    {
      label: (
        <Link href="/profile/wallet" className="link-dropdown-mb">
          <span className="text-link">เติมเงิน</span>
        </Link>
      ),
      key: '2',
    },
    {
      label: (
        <Link href="/logout" className="link-dropdown-mb">
          <span className="text-logout">ออกจากระบบ</span>
        </Link>
      ),
      key: '3',
    },
  ]

  return (
    <div className="flex flex-row justify-between items-end pb-3 gap-3 sm:gap-0">
      <div className="flex-auto w-41px sm:w-3/12">
        <div
          className="box-logo cursor-pointer"
          onClick={() => {
            router.push('/')
          }}
        >
          <Image src={LogoDeskTop} alt="logo" className="w-full" />
        </div>
        <div
          className="box-logo-mobile cursor-pointer"
          onClick={() => {
            router.push('/')
          }}
        >
          <Image src={LogoMobile} alt="logo" className="w-full" />
        </div>
      </div>
      <div className="flex-auto sm:w-6/12">
        <div className="custom-input-search">
          <Form
            name="search"
            onFinish={() => handleSearch()}
            autoComplete="off"
            className="relative"
            form={form}
          >
            <Form.Item label="" name="search_product" className="mb-0 relative">
              <Input placeholder="พิมพ์คำค้นหาและวางลิงก์สินค้าจากเว็บไซต์จีน" />
            </Form.Item>
            <Buttons className="btn btn-search btn-secondary" type="submit">
              <Icon.Search width={16} />
            </Buttons>
          </Form>
        </div>
      </div>
      <div className="flex-auto w-90px justify-end sm:w-3/12">
        {isLoggedIn ? (
          <>
            {isPhoneNumberVerified ? (
              <div className="flex justify-end gap-2">
                <ButtonCart notification={total ? true : false}>
                  {total ? total : null}
                </ButtonCart>
                <Dropdown
                  menu={{
                    items: login,
                  }}
                  trigger={['click']}
                  className="wrap-user-is-login"
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space className="user-is-login">
                      <Image src={UserLogin} alt="UserLogin" />
                    </Space>
                    <span className="text-is-login truncate ...">
                      {user ? user.given_name : null}
                    </span>
                  </a>
                </Dropdown>
              </div>
            ) : (
              <div className="flex justify-end gap-2 items-center">
                <Link href="/verifies" className="link-nav-verify">
                  <Image src={UserLogin} alt="UserLogin" />
                  <span className="pl-2">ยืนยันตัวตน</span>
                </Link>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-end">
            <div className="flex flex-row items-center">
              <Link href="/login" className="link-login">
                <UserOutlined className="pr-2" />
                สมัครสมาชิก
              </Link>
              <div className="line-register-right"></div>
              <Link href="/login" className="link-login">
                <DownloadOutlined className="icon-login" />
                เข้าสู่ระบบ
              </Link>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={['click']}
                className="warp-dropdown-mb"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space className="user-login-mb">
                    <Icon.LogIn width={16} color="#ff0000" />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
