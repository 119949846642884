import { useEffect, useState } from 'react'
import useSWR from 'swr'

const fetcher = (...args) => fetch(...args).then((res) => res.json())

export default function useNotification() {
  const [notification, setNotification] = useState({})
  const {
    data: respData,
    mutate: mutateNotification,
    isLoading,
  } = useSWR(`/api/fcm-tokens`, fetcher)

  useEffect(() => {
    const data = respData || {}
    setNotification(data)
  }, [respData])

  return { notification, mutateNotification, isLoading }
}
