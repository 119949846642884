'use client'
import './page.module.styles.css'
import { useEffect } from 'react'
import { Skeleton } from 'antd'
import * as Icon from 'react-feather'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Link from 'next/link'
import { Spin } from 'antd'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import useCategories from '../../lib/useCategories'
import useBanners from 'src/app/lib/useBanners'
import { Fallback } from 'src/app/helper'

export const Banner = () => {
  const { categories, mutateCategories, isLoading } = useCategories()
  const { banners, mutateBanners, isLoading: loadingBanner } = useBanners()

  const { data } = categories || {}
  useEffect(() => {
    mutateCategories
    mutateBanners
  }, [mutateCategories, mutateBanners])

  return (
    <Spin spinning={loadingBanner}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        slidesPerView={1}
        pagination={{ clickable: true, bulletElement: true }}
        parallax={true}
        className="slide-banner"
      >
        {banners &&
          banners.length > 0 &&
          banners.map((item) => {
            const { image } = item || {}
            const imageUrl = image && image?.data.map((item) => item.attributes)
            const link = item?.link
            return (
              <SwiperSlide
                key={item.name}
                style={{
                  backgroundImage: `url(${
                    imageUrl ? imageUrl[0]?.url : Fallback.text
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <Link href={link ? link : '/'}>
                  <div className="box-banner"></div>
                </Link>
              </SwiperSlide>
            )
          })}
        <div className="container mx-auto absolute-center z-10">
          <div className="box-banner-menu z-20">
            <div className="heder-menu">
              <Icon.AlignJustify width={18} className="text-white mr-2" />
              <span className="text-header">หมวดหมู่</span>
            </div>
            <Spin spinning={isLoading}>
              <div className="wrap-box-menu">
                {data?.slice(0, 9).map((item) => {
                  return (
                    <Link
                      key={`category_menu_${item.name}`}
                      href={`/category/${item.id}`}
                      className="link-banner-menu"
                    >
                      <span className="text-link-menu">{item.name}</span>
                      <Icon.ChevronRight
                        width={18}
                        className="text-link-menu"
                      />
                    </Link>
                  )
                })}
                {isLoading && (
                  <div className="flex flex-col justify-between box-skeleton">
                    <div className="flex">
                      <Skeleton className="w-skeleton" size="large" active />
                    </div>
                  </div>
                )}
              </div>
            </Spin>
          </div>
        </div>
      </Swiper>
    </Spin>
  )
}
