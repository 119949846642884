function preciseRound(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

const FormattedCurrencyBath = (value) => {
  const roundedValue = preciseRound(value, 4)

  return roundedValue
    ? roundedValue
        .toLocaleString('th-TH', {
          style: 'currency',
          currency: 'THB',
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        })
        .replace('฿', '฿ ')
    : '฿ 0.0000' // Ensure this matches the format used above for consistency
}

export default FormattedCurrencyBath
