function preciseRound(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

const FormattedCurrencyCNYTwoDecimals = (value) => {
  const roundedValue = preciseRound(value, 2)

  return roundedValue
    ? roundedValue
        .toLocaleString('zh-CN', {
          style: 'currency',
          currency: 'CNY',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replace('¥', 'CNY ¥ ')
    : 'CNY ¥ 0.00' // Changed to return a formatted string for consistency
}
export default FormattedCurrencyCNYTwoDecimals
