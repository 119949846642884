// Precise rounding function
function preciseRound(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

// Function to format currency in Thai Baht
const FormattedCurrencyExchangeRateBath = (valueCNY, exchangeRate) => {
  const valueTHB = valueCNY * exchangeRate // Convert CNY to THB
  const roundedValue = preciseRound(valueTHB, 4) // Round to 4 decimal places

  return roundedValue
    ? roundedValue
        .toLocaleString('th-TH', {
          style: 'currency',
          currency: 'THB',
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        })
        .replace('฿', '฿ ')
    : '฿ 0.0000' // Fallback if conversion results in 0
}

export default FormattedCurrencyExchangeRateBath
