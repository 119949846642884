'use client'
import { useEffect } from 'react'
import Link from 'next/link'
import { ConfigProvider } from 'antd'
import './page.module.styles.css'
import { NavBar } from '../navbar/index'
import Footer from '../../footer/page'
import Image from 'next/image'
import IconHome from '../../../image/icon/icon-home.svg'
import IconHomeInactive from '../../../image/icon/icon-home-inactive.svg'
import IconPromotion from '../../../image/icon/icon-promotion.svg'
import IconPromotionInactive from '../../../image/icon/icon-promotion-inactive.svg'
import IconService from '../../../image/icon/icon-service.svg'
import IconServiceInactive from '../../../image/icon/icon-service-inactive.svg'
import IconBell from '../../../image/icon/icon-bell.png'
import IconBellInactive from '../../../image/icon/icon-bell-inactive.png'
import IconHelp from '../../../image/icon/icon-help.svg'
import IconHelpInactive from '../../../image/icon/icon-help-inactive.svg'
import { usePathname } from 'next/navigation'
import useNotification from 'src/app/lib/useNotification'
import { filter, size } from 'lodash'
export const MainLayout = ({
  children,
  disableSideBar,
  disableSideBarMobile,
  className,
}) => {
  const pathname = usePathname()
  const { notification, mutateNotification } = useNotification()

  let unreadCount = size(filter(notification, { is_read: false })) || 0

  useEffect(() => {
    mutateNotification()
  }, [mutateNotification])
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'var(--DBHeavent)',
        },
        components: {
          Input: {
            borderRadius: 30,
            controlOutline: 'transparent',
            colorPrimaryActive: '#FF0000',
            colorPrimaryHover: '#FF0000',
            colorError: '#FF0000',
          },
          InputNumber: {
            borderRadius: 30,
            controlOutline: 'transparent',
            colorError: '#FF0000',
            colorPrimaryHover: '#FF0000',
          },
          Button: {
            colorLinkHover: '#FF0000',
            colorLinkActive: '#FF0000',
            colorLink: '#ff0000',
            // colorLinkActive: '#ff0000',
          },
          Checkbox: {
            colorPrimary: '#FF0000',
            colorPrimaryHover: '#FF0000',
            colorError: '#FF0000',
          },
          Slider: {
            colorFillTertiary: '#E3E3E3',
            colorPrimaryBorder: '#FF0000',
            colorPrimaryBorderHover: '#FF0000',
          },
          Select: {
            controlItemBgActive: '#E3E3E3',
            controlOutline: '#E3E3E3',
            fontSize: 18,
            colorError: '#FF0000',
            borderRadius: 30,
            // controlOutline: 'transparent',
            colorPrimaryHover: '#FF0000',
          },
          Pagination: {
            borderRadius: '45px',
          },
          Radio: {
            colorBorder: '#E3E3E3',
            colorPrimary: '#FF0000',
            colorPrimaryHover: '#FF0000',
            colorError: '#FF0000',
          },
          Modal: {
            borderRadiusLG: '20px',
          },
          DatePicker: {
            fontSize: 24,
            colorLink: '#FF0000',
            colorPrimary: '#FF0000',
            colorText: '#303132',
            controlItemBgHover: '#FEEAEA',
          },
          Spin: {
            colorPrimary: '#FF0000',
          },
          Dropdown: {
            colorPrimary: '#333333',
            controlItemBgActive: '#fceceb',
            controlItemBgActiveHover: '#fceceb',
            controlItemBgHover: '#fceceb',
            // fontSize: '20px',
          },
        },
      }}
    >
      <div className={`w-full ${className}`}>
        <div className="w-full bg-gray-f5">
          <NavBar />
        </div>
        <div className="container-space-top">{children}</div>
        {!disableSideBar ? (
          <>
            <div className="fixed z-20 absolute-right-bar w-full sm:w-auto">
              <div
                className={
                  disableSideBarMobile
                    ? `warp-nav-right-hidden`
                    : `warp-nav-right`
                }
              >
                <Link
                  href="/"
                  className={`link-right-menu ${
                    pathname === '/' && 'right-active'
                  }`}
                >
                  {pathname === '/' ? (
                    <Image src={IconHome} alt="icon-home" className="w-8" />
                  ) : (
                    <Image
                      src={IconHomeInactive}
                      alt="icon-home"
                      className="w-8"
                    />
                  )}
                  <span className="text-right-menu pt-2">หน้าแรก</span>
                </Link>
                <Link
                  href="/contents/promotion"
                  className={`link-right-menu ${
                    pathname === '/contents/promotion' && 'right-active'
                  }`}
                >
                  {pathname === '/contents/promotion' ? (
                    <Image
                      src={IconPromotionInactive}
                      alt="icon-home"
                      className="w-6"
                    />
                  ) : (
                    <Image
                      src={IconPromotion}
                      alt="icon-home"
                      className="w-6"
                    />
                  )}
                  <span className="text-right-menu pt-2">โปรโมชั่น</span>
                </Link>
                <Link
                  href="/contents/article-shipping-cost"
                  className={`link-right-menu ${
                    pathname === '/contents/article-shipping-cost' &&
                    'right-active'
                  }`}
                >
                  {pathname === '/contents/article-shipping-cost' ? (
                    <Image
                      src={IconServiceInactive}
                      alt="icon-home"
                      className="w-6"
                    />
                  ) : (
                    <Image src={IconService} alt="icon-home" className="w-6" />
                  )}
                  <span className="text-right-menu pt-2">ค่าจัดส่ง</span>
                </Link>
                <Link
                  href="/profile/notification"
                  className={`link-right-menu ${
                    pathname === '/profile/notification' && 'right-active'
                  }`}
                >
                  {pathname === '/profile/notification' ? (
                    <Image
                      src={IconBellInactive}
                      alt="icon-bell"
                      className="w-6"
                    />
                  ) : (
                    <Image src={IconBell} alt="icon-bell" className="w-6" />
                  )}
                  <span className="text-right-menu pt-2">การแจ้งเตือน</span>
                  {unreadCount > 0 && (
                    <div className="dot-active-nav">{unreadCount}</div>
                  )}
                </Link>
                <Link
                  href="/profile/about-me"
                  className={`link-right-menu ${
                    pathname === '/profile/about-me' && 'right-active'
                  }`}
                >
                  {pathname === '/profile/about-me' ? (
                    <Image
                      src={IconHelpInactive}
                      alt="icon-home"
                      className="w-6"
                    />
                  ) : (
                    <Image src={IconHelp} alt="icon-home" className="w-6" />
                  )}
                  <span className="text-right-menu pt-2">ข้อมูลส่วนตัว</span>
                </Link>
              </div>
            </div>
            {/* <div className="fixed z-20 absolute-right-bottom-bar">
              <Link href="#" className="box-chat">
                <Image src={IconChat} alt="chat" className="mr-3" />
                <span className="text-chat">Chat</span>
              </Link>
            </div> */}
          </>
        ) : null}
        <Footer />
      </div>
    </ConfigProvider>
  )
}
