'use client'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import _ from 'lodash'
const fetcher = (...args) => fetch(...args).then((res) => res.json())

export default function useBanners() {
  const [banners, setBanners] = useState({})
  const [bannerFlashSale, setFlashSale] = useState({})
  const [shippingHome, setShipping] = useState({})
  const [campaignHome, setCampaign] = useState({})
  const {
    data: respData,
    mutate: mutateBanners,
    isLoading,
  } = useSWR(`/api/banner`, fetcher)

  useEffect(() => {
    const data = respData || {}
    const groupBanner = _.groupBy(data, 'group')
    setBanners(groupBanner?.homeBannerSlide)
    setFlashSale(groupBanner?.flashSaleHome)
    setShipping(groupBanner?.shippingHome)
    setCampaign(groupBanner?.campaignHome)
  }, [respData])

  return {
    banners,
    bannerFlashSale,
    shippingHome,
    campaignHome,
    mutateBanners,
    isLoading,
  }
}
