'use client'

import './page.module.styles.css'
import IconCart from '../../../image/icon/custom-icon-cart.svg'
import Image from 'next/image'
import Link from 'next/link'

export const Buttons = ({
  children,
  className,
  onOk,
  disable,
  type,
  ...props
}) => {
  return (
    <button
      className={`btn ${className}`}
      onClick={onOk}
      disabled={disable}
      type={type}
      {...props}
    >
      {children}
    </button>
  )
}

export const ButtonLink = ({
  href,
  children,
  className,
  onOk,
  disable,
  type,
}) => {
  return (
    <Link
      href={href}
      className={`btn ${className}`}
      onClick={onOk}
      aria-disabled={disable}
      type={type}
    >
      {children}
    </Link>
  )
}

export const ButtonCart = ({ children, className, notification }) => {
  return (
    <Link href="/cart" className={`btn-cart ${className}`}>
      <span className="box-icon-cart">
        <Image src={IconCart} alt="cart" className="relative cm-icon-cart" />
      </span>
      {notification ? <span className="noti-cart">{children}</span> : null}
    </Link>
  )
}
