import { initializeApp } from 'firebase/app'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBBfpEMXqGJ2PbMB96OV9bmmrTfiKEchQE',
  authDomain: 'one-mall-shop.firebaseapp.com',
  projectId: 'one-mall-shop',
  storageBucket: 'one-mall-shop.appspot.com',
  messagingSenderId: '791461114488',
  appId: '1:791461114488:web:508160f06806cb57aaf38d',
  measurementId: 'G-TE3WBNE7DB',
}

const app = initializeApp(firebaseConfig)

export default app
