'use client'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import qs from 'qs'
const fetcher = (...args) => fetch(...args).then((res) => res.json())

export default function useProductsFlashSale(options) {
  const [flashSale, setFlashSale] = useState([])
  const queryString = qs.stringify(options)
  const {
    data: respData,
    mutate: mutateFlashSale,
    isLoading: loading,
  } = useSWR(`/api/products/flash-sale?${queryString}`, fetcher)

  useEffect(() => {
    const { data } = respData || {}
    setFlashSale(data)
  }, [respData])

  return { flashSale, mutateFlashSale, loading }
}
