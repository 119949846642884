'use client'
import { useEffect } from 'react'
import useSWR from 'swr'
import { usePathname, useRouter } from 'next/navigation'

const fetcher = (...args) => fetch(...args).then((res) => res.json())

export default function useUser({
  redirectTo = '',
  redirectIfFound = false,
} = {}) {
  const { data: user, mutate: mutateUser } = useSWR('/api/user', fetcher)
  const pathname = usePathname()
  const router = useRouter()
  //
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      !pathname.includes('login') &&
      !pathname.includes('realms')
    ) {
      localStorage.setItem('lastPathname', pathname)
    }
  }, [pathname])

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    // if (!redirectTo || !user) {
    //   return
    // }
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user?.isLoggedIn)
    ) {
      const { redirect_uri } = user || {}
      if (redirect_uri) {
        // window.location = redirect_uri
        router.push(redirect_uri)
      }
    }
  }, [user, redirectIfFound, redirectTo, router])

  return { user, mutateUser }
}
