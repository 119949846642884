'use client'
import { useEffect, useState, useCallback } from 'react'
import Image from 'next/image'
import { Tabs, Spin } from 'antd'
import './page.module.styles.css'
import * as Icon from 'react-feather'
import { useRouter } from 'next/navigation'
import CardProduct from '../card/page'
import IconTaobao from '../../image/icon/icon-partner-taoboo.png'
// import IconTMall from '../../image/icon/icon-partner-tmall.png'
import Icon1688 from '../../image/icon/icon-partner-1688.png'
import useProductsForYou from '../../lib/for-you/useProductsForYou'
import { debounce } from 'lodash'

const ForYouProducts = () => {
  const router = useRouter()
  const [option, setOption] = useState({ source: '' })
  const [currentPage, setCurrentPage] = useState(1)
  const [isFetching, setIsFetching] = useState(false)
  const [accumulatedItems, setAccumulatedItems] = useState([])
  const [fetchCount, setFetchCount] = useState(0)
  const itemsPerPage = 30
  const { forYou, mutateForYou, loading } = useProductsForYou({
    ...option,
    page: currentPage,
    limit: itemsPerPage,
  })

  useEffect(() => {
    if (forYou?.data) {
      setAccumulatedItems((prevItems) => [...prevItems, ...forYou.data])
    }
  }, [forYou?.data])

  useEffect(() => {
    const trackScrolling = debounce(() => {
      const reachedEndOfPage =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 500
      // Check if not already fetching to prevent duplicate fetches
      if (reachedEndOfPage && !isFetching && fetchCount < 3) {
        setIsFetching(true) // Indicate fetching is starting
        setCurrentPage((prevPage) => prevPage + 1) // Increment page to trigger fetch in the effect
      }
    }, 100)

    window.addEventListener('scroll', trackScrolling)
    return () => window.removeEventListener('scroll', trackScrolling)
  }, [fetchCount, isFetching]) // Removed isFetching from dependencies to avoid re-adding the event listener unnecessarily

  useEffect(() => {
    if (isFetching) {
      const fetchItems = async () => {
        try {
          await mutateForYou({ ...option, page: currentPage })
          setFetchCount((prevCount) => prevCount + 1) // Increment fetch count
        } catch (error) {
          console.error('Error fetching data:', error)
        } finally {
          setIsFetching(false) // Ensure isFetching is reset after fetch completes or fails
        }
      }

      fetchItems()
    }
  }, [currentPage, isFetching, mutateForYou, option]) // Ensure all necessary dependencies are listed

  useEffect(() => {
    // Reset accumulated items and fetch count when option changes
    setAccumulatedItems([])
    setCurrentPage(1)
    setFetchCount(0)
    setIsFetching(true)
  }, [option])

  const onChange = useCallback((key) => {
    let newSource = ''
    if (key === '2') {
      newSource = 'taobao'
    } else if (key === '3') {
      newSource = '1688'
    }
    setOption((prevOptions) => ({
      ...prevOptions,
      source: newSource,
    }))
  }, [])

  const items = [
    {
      key: '1',
      label: <span className="cm-text-tab">ทั้งหมด</span>,
      children: (
        <>
          <PartnerProduct data={accumulatedItems} router={router} />
          {loading && (
            <div className="flex justify-center mt-4 min-height-tab-component">
              <Spin />
            </div>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: (
        <span className="cm-text-tab">
          <Image src={IconTaobao} alt="Taobao" width={28} />
          Taobao
        </span>
      ),
      children: (
        <>
          <PartnerProduct data={accumulatedItems} router={router} />
          {loading && (
            <div className="flex justify-center mt-4  min-height-tab-component">
              <Spin />
            </div>
          )}
        </>
      ),
    },
    {
      key: '3',
      label: (
        <span className="cm-text-tab">
          <Image src={Icon1688} alt="1688" width={28} />
          1688
        </span>
      ),
      children: (
        <>
          <PartnerProduct data={accumulatedItems} router={router} />
          {loading && (
            <div className="flex justify-center mt-4  min-height-tab-component">
              <Spin />
            </div>
          )}
        </>
      ),
    },
  ]
  return (
    <div className="w-full flex mt-4 sm:mt-10  min-height-tab-component">
      <div className="container mx-auto">
        <div className="flex flex-col wrap-slide-recommend">
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-row items-center">
              <h3 className="text-heder-component">สินค้าเหมาะกับคุณ</h3>
            </div>
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => {
                router.push(`/product-for-you?search=${'for-you'}`, undefined, {
                  shallow: true,
                })
              }}
            >
              <span className="text-see-all">ดูทั้งหมด</span>
              <div className="icon-see-all">
                <Icon.ArrowRight className="w-3 h-3" />
              </div>
            </div>
          </div>
          <div className="flex">
            <span className="underline-text"></span>
          </div>
          <div className="flex w-full">
            <Tabs
              defaultActiveKey="1"
              items={items}
              onChange={onChange}
              className="cm-tab-home"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ForYouProducts

export const PartnerProduct = ({ data, router }) => {
  return (
    <div className="w-full flex mt-4 sm:mt-8">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 gap-3 sm:gap-4">
        {data?.map((item, index) => {
          return (
            <div className="block" key={index}>
              <CardProduct
                imageUrl={item?.pic_url}
                alt={item?.title}
                tagName={item?.source}
                title={item?.title}
                priceTHB={item && item.priceBaht ? item.priceBaht : '00.00'}
                priceCNY={item && item.price ? item.price : '00.00'}
                className="card-padding"
                onClick={() => {
                  router.push(`/product/for-you/${item.num_iid}/${item.source}`)
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
