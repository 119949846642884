'use client'
import { useEffect, useState } from 'react'
import './page.module.styles.css'
import { Spin } from 'antd'
import CardProduct from '../card/page'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import * as Icon from 'react-feather'
import { useRouter } from 'next/navigation'
import useProductsRecommended from '../../lib/recommended/useProductsRecommended'

const ReccomendComponent = ({ disableSeeAll }) => {
  const router = useRouter()
  const [option, setOption] = useState({
    page: 1,
    limit: 30,
  })
  const { recommend, mutateRecommend, loading } = useProductsRecommended(option)
  const { data } = recommend || {}

  useEffect(() => {
    mutateRecommend
    setOption((prevOption) => ({
      ...prevOption,
      page: 1,
    }))
  }, [mutateRecommend])

  return (
    <Spin spinning={loading}>
      <div className="w-full flex mt-4 sm:mt-10">
        <div className="container mx-auto">
          <div className="flex flex-col wrap-slide-recommend">
            <div className="flex w-full justify-between items-center">
              <div className="flex flex-row items-center">
                <h3 className="text-heder-component">สินค้าแนะนำ</h3>
              </div>
              {!disableSeeAll ? (
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() => {
                    router.push(
                      `/product-recommend?search=${'recommend'}`,
                      undefined,
                      {
                        shallow: true,
                      },
                    )
                  }}
                >
                  <span className="text-see-all">ดูทั้งหมด</span>
                  <div className="icon-see-all">
                    <Icon.ArrowRight className="w-3 h-3" />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex">
              <span className="underline-text"></span>
            </div>
            <div className="flex w-full warp-card-reccomend">
              <Swiper
                className="cm-swiper-dot"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                loop={true}
                navigation
                slidesPerView={2.25}
                spaceBetween={10}
                breakpoints={{
                  640: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                  1280: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                  1536: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                  },
                }}
              >
                {/* slice(0, 30) */}
                {data?.map((item) => {
                  return (
                    <SwiperSlide key={`recommend_${item.id}`}>
                      <CardProduct
                        imageUrl={item?.pic_url}
                        alt={item?.title}
                        tagName={item?.source}
                        title={item?.title}
                        priceTHB={
                          item && item.priceBaht ? item.priceBaht : '00.00'
                        }
                        priceCNY={item && item.price ? item.price : '00.00'}
                        className="custom-shadow"
                        onClick={() => {
                          router.push(
                            `/product/recommended/${item.num_iid}/${item.source}`,
                          )
                        }}
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}
export default ReccomendComponent
