'use client'
import { MainLayout } from './components/ui/main-layout'
import { useEffect, useState } from 'react'
import './home.module.styles.css'
import Link from 'next/link'
import { Button, Drawer, Spin, Image as AntImage } from 'antd'
import Image from 'next/image'
import { Banner } from './components/banner'
import Campaign from './components/campaign/page'
import RecommendComponent from './components/recommended'
import ForYouProducts from './components/for-you/page'
import IconLink1 from './image/icon/icon-link-out-1.png'
import IconLink2 from './image/icon/icon-link-out-2.png'
import IconLink3 from './image/icon/icon-link-out-3.png'
import IconLink4 from './image/icon/icon-link-out-4.png'
import * as Icon from 'react-feather'
import useBanners from './lib/useBanners'
import useCategories from './lib/useCategories'

export default function Index() {
  // const { fcmToken, fcmPermission } = useFcm()
  // fcmToken && console.log('FCM token:', fcmToken)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MainLayout>
      <div className="w-100 block">
        <>
          <div className="relative">
            <Banner />
            <NavMenuPartner />
            <BannerAdHome />
            <Campaign />
            <RecommendComponent />
            <LinkBanner />
            <ForYouProducts />
          </div>
        </>
      </div>
    </MainLayout>
  )
}

export const NavMenuPartner = () => {
  const [openMenu, setOpenMenu] = useState()
  return (
    <div className="flex py-4 sm:py-6">
      <div className="container mx-auto flex overflow-x-scroll overflow-y-hidden whitespace-nowrap space-x-4 w-full justify-start sm:justify-between">
        <div className="menu-category-mobile">
          <Button
            className="mb-category"
            onClick={() => {
              setOpenMenu(true)
            }}
          >
            <Icon.AlignJustify width={30} />
          </Button>
        </div>
        <Link href="https://world.taobao.com/" target="_blank">
          <div className="link-out-side">
            <Image src={IconLink1} alt="1" />
          </div>
        </Link>
        <Link href="https://www.tmall.com/" target="_blank">
          <div className="link-out-side">
            <Image src={IconLink2} alt="1" />
          </div>
        </Link>
        <Link href="https://www.1688.com/" target="_blank">
          <div className="link-out-side">
            <Image src={IconLink3} alt="1" />
          </div>
        </Link>
        <Link href="/" target="_blank">
          <div className="link-out-side">
            <Image src={IconLink4} alt="1" />
          </div>
        </Link>
      </div>
      <CategoryMobile openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </div>
  )
}

export const CategoryMobile = ({ openMenu, setOpenMenu }) => {
  const { categories, isLoading, mutateCategories } = useCategories()
  const { data } = categories || {}

  useEffect(() => {
    mutateCategories
  }, [mutateCategories])
  return (
    <Drawer
      title={<span className="text-title-drawer">หมวดหมู่</span>}
      placement="left"
      onClose={() => {
        setOpenMenu(false)
      }}
      open={openMenu}
      className="custom-drawer"
    >
      <Spin spinning={isLoading}>
        {/* .slice(0, 30) */}
        {data?.map((item) => {
          return (
            <Link
              key={item.name}
              href={`/category/${item.id}`}
              className="link-menu"
            >
              <span className="text-menu">{item.name}</span>
              <Icon.ChevronRight width={18} className="text-menu" />
            </Link>
          )
        })}
      </Spin>
    </Drawer>
  )
}

export const BannerAdHome = () => {
  const { shippingHome, mutateBanners, isLoading: loadingBanner } = useBanners()

  const data =
    shippingHome && shippingHome.length > 0 && shippingHome.map((item) => item)

  const image1 =
    data && data?.find((item) => item?.slug === 'shipping-home-banner-1')
  const imageUrl1 = image1?.image?.data[0]?.attributes?.url

  const image2 =
    data && data?.find((item) => item?.slug === 'shipping-home-banner-2')
  const imageUrl2 = image2?.image?.data[0]?.attributes?.url

  const image3 =
    data && data?.find((item) => item?.slug === 'shipping-home-banner-3')
  const imageUrl3 = image3?.image?.data[0]?.attributes?.url

  useEffect(() => {
    mutateBanners
  }, [mutateBanners])
  return (
    <Spin spinning={loadingBanner}>
      <div className=" bg-white py-4 sm:py-6">
        <div className="container mx-auto">
          {data && (
            <div className="flex flex-row gap-1 sm:gap-3">
              <div className="flex">
                <Link
                  href={`${shippingHome[0]}` ? `${shippingHome[0]?.link}` : '/'}
                >
                  <AntImage
                    src={imageUrl1}
                    alt={`${image1 ? image1?.title : `BannerShipping1`}`}
                    className="w-full"
                    preview={{ visible: false, mask: false }}
                  />
                </Link>
              </div>
              <div className="flex flex-col justify-between">
                <Link
                  href={`${shippingHome[1]}` ? `${shippingHome[1]?.link}` : '/'}
                >
                  <AntImage
                    src={imageUrl2}
                    alt={`${image2 ? image2?.title : `BannerShipping2`}`}
                    className="w-full"
                    preview={{ visible: false, mask: false }}
                  />
                </Link>
                <Link
                  href={`${shippingHome[2]}` ? `${shippingHome[2]?.link}` : '/'}
                >
                  <AntImage
                    src={imageUrl3}
                    alt={`${image3 ? image3?.title : `BannerShipping3`}`}
                    className="w-full"
                    preview={{ visible: false, mask: false }}
                  />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Spin>
  )
}

export const LinkBanner = () => {
  const { campaignHome, mutateBanners, isLoading: loadingBanner } = useBanners()

  const data =
    campaignHome && campaignHome.length > 0 && campaignHome.map((item) => item)

  const image1 =
    data && data?.find((item) => item?.slug === 'campaign-home-banner-1')
  const imageUrl1 = image1?.image?.data[0]?.attributes?.url

  const image2 =
    data && data?.find((item) => item?.slug === 'campaign-home-banner-2')
  const imageUrl2 = image2?.image?.data[0]?.attributes?.url

  useEffect(() => {
    mutateBanners
  }, [mutateBanners])
  return (
    <Spin spinning={loadingBanner}>
      <div className="w-full flex mt-8 mb-8 sm:mt-10 sm:mb-0">
        <div className="container mx-auto">
          {data && (
            <div className="flex flex-col sm:flex-row gap-3 sm:gap-6">
              <Link
                href={
                  `${campaignHome[1]?.link}` ? `${campaignHome[1]?.link}` : '/'
                }
                className="flex"
              >
                <AntImage
                  src={imageUrl1}
                  className="w-full"
                  preview={{ visible: false, mask: false }}
                  alt={`${image1 ? image1?.title : `BannerCampaign1`}`}
                />
              </Link>
              <Link
                href={
                  `${campaignHome[0]?.link}` ? `${campaignHome[0]?.link}` : '/'
                }
                className="flex"
              >
                <AntImage
                  src={imageUrl2}
                  className="w-full"
                  preview={{ visible: false, mask: false }}
                  alt={`${image2 ? image2?.title : `BannerCampaign2`}`}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </Spin>
  )
}
