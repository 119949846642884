'use client'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
const fetcher = (...args) => fetch(...args).then((res) => res.json())

export default function useCategories() {
  const [categories, setCategories] = useState([])
  const {
    data: respData,
    mutate: mutateCategories,
    isLoading,
  } = useSWR('/api/categories', fetcher)

  useEffect(() => {
    const { data } = respData || {}
    setCategories(data)
  }, [respData])

  return { categories, mutateCategories, isLoading }
}
