function preciseRound(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

const FormattedCurrencyBathTwoDecimals = (value) => {
  const roundedValue = preciseRound(value, 2)

  return roundedValue
    ? roundedValue
        .toLocaleString('th-TH', {
          style: 'currency',
          currency: 'THB',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replace('฿', '฿ ')
    : '฿ 0.00' // Ensure this matches the format used above for consistency
}

export default FormattedCurrencyBathTwoDecimals
