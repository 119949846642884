import { notification } from 'antd'

export const HandleError = (message, description) => {
  notification.error({
    message: message || '',
    description: description || '',
  })
}

export const HandleSuccess = (message, description) => {
  notification.success({
    message: message || '',
    description: description || '',
  })
}

export const HandleWarning = (message, description) => {
  notification.warning({
    message: message || '',
    description: description || '',
  })
}
