import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from './en/translation.json'
import translationTH from './th/translation.json'

i18next
  .use(initReactI18next) // Passes i18next instance to react-i18next.
  .init({
    lng: 'th', // Default language
    debug: false, // Set to true if you want to see logs
    resources: {
      en: {
        translation: translationEN, // English translations
      },
      th: {
        translation: translationTH, // Thai translations
      },
    },
  })
