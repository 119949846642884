'use client'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import qs from 'qs'
const fetcher = (...args) => fetch(...args).then((res) => res.json())

export default function useProductsRecommended(options) {
  const [recommend, setRecommend] = useState([])
  const queryString = qs.stringify(options)
  const {
    data: respData,
    mutate: mutateRecommend,
    isLoading: loading,
  } = useSWR(`/api/products/recommended?${queryString}`, fetcher)

  useEffect(() => {
    const { data } = respData || {}
    setRecommend(data)
  }, [respData])

  return { recommend, mutateRecommend, loading }
}
