function preciseRound(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

const FormattedCurrencyCNY = (value) => {
  const roundedValue = preciseRound(value, 4)

  return roundedValue
    ? roundedValue
        .toLocaleString('zh-CN', {
          style: 'currency',
          currency: 'CNY',
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        })
        .replace('¥', 'CNY ¥ ')
    : 'CNY ¥ 0.0000' // Changed to return a formatted string for consistency
}
export default FormattedCurrencyCNY
